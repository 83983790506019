import {updatePosListUser} from "./form-user";

const $ = require('jquery');

import {
    handlePopinAction,
    handleSendReminder, launchTooltip,
    openConfirmation,
    popLink,
    toggleSectionContentVisibility, translate
} from './functions';


export function handleHomePaginationLinkAction(bloc)
{
    let paginationLink = $('.paginationLink.--leads');
    let sort = 'date';
    let order = 'desc';
    let sortArrow = $('.sort-arrow.selected');
    if (sortArrow.length > 0) {
        if (sortArrow.hasClass('sort-asc')) {
            sort = 'asc';
        }
        sort = sortArrow.data('sort-element-name');
    }

    paginationLink.unbind('click').click(function(){
        let page = $(this).attr('data-page');
        loadLeads(page, bloc, false, sort, order)
    });
}



function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    let urlparts = url.split('?');
    if (urlparts.length >= 2) {

        let prefix = encodeURIComponent(parameter) + '=';
        let pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (let i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + '?' + pars.join('&');
        return url;
    } else {
        return url;
    }
}



let requestByScrollProgress = false;
let lastAjaxLoadLead = undefined;

function loadLeads(page, bloc, title, sort, order) {

    if (title) {
        $('.board h3 span').html(title);
    }
    let loader = $('.common-loader');
    loader.removeClass('--hidden');
    $('.board .results').html('');

    let url = $('#currentUrl').val();
    url = removeURLParameter(url, "bloc");
    lastAjaxLoadLead = $.ajax({
        url: url,
        data: {'page': page, 'bloc': bloc, 'sort': sort, 'orderby': order},
        type: 'GET',
        success: function (html) {
            //si on est déconnecté : rediriger vers la page de login
            if (html.includes('loginForm')) {
                window.location = Routing.generate('app_login');
                return;
            }
            requestByScrollProgress = false;
            loader.addClass('--hidden');
            $('.board .results').append(html).removeClass('--hidden');
            handleHomePaginationLinkAction(bloc);
            handlePopinAction();
            sortLeads();
            applyCti();
            popLink();
            handleSendReminder();
            handleLeadCheckboxes();
            launchTooltip();
        }
    });
    return false;
}

function sortLeads() {
    $('.sort-arrow').click(function(){
        let order = 'asc';
        if ($(this).hasClass('sort-desc')) {
            order = 'desc';
        }
        let sort = $(this).data('sort-element-name');
        let block = $('.board .leads-table .section-content').data('block');
        $(this).addClass('selected');
        loadLeads(1, block, false, sort, order);
    })
}

const applyCti = function () {

    const cti_use = $('#cti_use').data('cti');
    if (cti_use === 'viadialog') {
        $('.confirm-call').click(function (event) {
            event.preventDefault();
            return confirm("Etes-vous sûr de vouloir appeler ce numéro ?")
        });
    } else if (cti_use === 'onc') {
        $('.confirm-call').click(function (event) {
            event.preventDefault();
            const num = $(this).data('num');
            window.external.Rcp2HtmlTabMakeCall(num, null);
            return false;
        });
    }
};

function handleLeadCheckboxes()
{
    $('.lead-checkbox-all').on('change', function() {
        if ($(this).is(':checked')) {
            $('.lead-checkbox').prop('checked', true).trigger('change');
        } else {
            $('.lead-checkbox').prop('checked', false).trigger('change');
        }
    })
    $('.lead-checkbox').on('change', function() {
        if ($('.lead-checkbox:checked').length > 0) {
            $('.batch-action').removeClass('--hidden');
        } else {
            $('.batch-action').addClass('--hidden');
        }
    })

    $('#batchRelaunch').on('click', function(event) {
        event.preventDefault();
        let ids = [];
        $(".lead-checkbox:checked").each(function(){
            ids.push($(this).val());
        });
        let nb = ids.length;
        let message = translate('crm.lead.relaunch_confirm', {'nb': nb})
        if (nb <= 1) {
            message = translate('crm.lead.relaunch_confirm1')
        }
        openConfirmation({'class': '--white', 'text': message, 'confirmText': translate('crm.common.confirm')});
        let url = Routing.generate('lead_batch_relaunch', {'ids': ids});
        const popConfirmation = $('body > .pop-confirmation');
        popConfirmation.find('.confirm').on('click', function() {
            //TODO loader avant de fermer la popin
            $.get(url)
                .done(function(data) {
                    if (data.result.refused.length > 0) {
                        let message = '';
                        data.result.refused.forEach(function (alias) {
                            if (message !== '') {
                                message += '<br />';
                            }
                            message += translate('crm.lead.relaunch_refused', {'alias': alias});
                        });
                        $('.js-flash-message-error .text').html(message);
                        $('.js-flash-message-error').removeClass('--hidden');
                    }
                    if (data.result.error.length > 0) {
                        $('.js-flash-message-error .text').html(translate('crm.lead.relaunch_error', {'leads_ids': data.result.error.join(', ')}));
                        $('.js-flash-message-error').removeClass('--hidden');
                    }
                    if (data.result.sent.length > 0) {
                        $('.js-flash-message-success .text').html(translate('crm.lead.relaunch_done', {'leads_ids': data.result.sent.join(', ')}));
                        $('.js-flash-message-success').removeClass('--hidden');
                        setTimeout(function() {
                            $('.js-flash-message-success').addClass('--hidden');
                        }, 15000);
                    }

                    $('html, body').animate({
                        scrollTop: $(".flash-message:visible").first().offset().top
                    }, 1000); // La durée du scroll en millisecondes
                    $(".lead-checkbox:checked").prop('checked', false);

                    //TODO flash error / success
                });
        })
    })

    $('#batchReaffect').on('click', function(event) {
        event.preventDefault();

        let ids = [];
        $(".lead-checkbox:checked").each(function(){
            ids.push($(this).val());
        });
        openConfirmation({'class': '--white --high', 'element': $("#batch-form-modal"), 'afterClose': afterClose});

        let url = Routing.generate('lead_batch_reaffect', {'ids': ids});

        let requestHeaders = new Headers({
            'X-Requested-With': 'XMLHttpRequest'
        });
        let requestInit = { method: 'GET',
            headers: requestHeaders,
        };

        fetch(url, requestInit)
            .then(response => response.json())
            .then(data => {
                if (data.template) {

                    $('.batch-form-modal-content').html(data.template).removeClass('--hidden');
                    $('.batch-form-modal-loader-wrapper').addClass('--hidden');
                    let form = $('.reassign-lead-form-wrapper');
                    form.find('.confirm').on('click', function() {
                        validateFormAction();
                    })
                    form.find('.cancel').on('click', function() {
                        afterClose();
                        $('body > .pop-overlay').addClass('--hidden');
                        $('body > .pop-confirmation').addClass('--hidden');
                    })

                    const posSelect = $('.reaffectPosLeadLine-posSelect');
                    const sellerSelect = $('.reaffectPosLeadLine-sellerSelect');

                    posSelect.select2();
                    sellerSelect.select2();
                    updatePosListUser(posSelect);
                }
            })
            .catch(function(error) {
                console.log(error);
                console.log('Server error');
            });
    })
}

$(document).on('input change', '.reassign-lead-form select', function() {
    if ($(this).val() === null || $(this).val().trim() === '') {
        $(this).addClass('error');
    } else {
        $(this).removeClass('error');
    }
});

function validateFormAction()
{
    let form = $('.pop-confirmation .reassign-lead-form');
    let url = form.attr('action');

    // Vérifier si tous les champs sont remplis
    let allFieldsFilled = true;

    form.find('select').each(function() {
        if ($(this).val() === null || $(this).val().trim() === '') {
            allFieldsFilled = false;
        }
    });

    if (!allFieldsFilled) {
        return; // Bloquer la soumission du formulaire
    }

    let formSerialize = form.serialize();

    $('.batch-form-modal-content').addClass('--hidden');
    $('.batch-form-modal-loader-wrapper').removeClass('--hidden');

    $.post(url, formSerialize, function(response) {
        if(response.status === 200) {
            location.reload(); //TODO reloader uniquement les leads ?
        }
    }).fail(function(error) {
        $('.batch-form-modal-loader-wrapper').addClass('--hidden');
        $('.batch-form-modal-content').html(error.statusText).removeClass('--hidden');
        toggleSectionContentVisibility();
        console.log(error);
        console.log('Server error');
    });
}

function afterClose()
{
    $('.batch-form-modal-content').html('');
    $('.batch-form-modal-loader-wrapper').removeClass('--hidden');
    $.get(Routing.generate('default_popin'), function (data) {
        $('.pop-confirmation').replaceWith(data);
    });
}

$(function () {
    $('#mainMenu a').not('#popupBanner').click(function () {
        if (lastAjaxLoadLead !== undefined) {
            lastAjaxLoadLead.abort();
        }
    });

    $('.stat-dashboard .value').click(function (event) {
        $('.stat-dashboard .value').removeClass('--check');
        $(this).addClass('--check');

        event.preventDefault();

        if (requestByScrollProgress && lastAjaxLoadLead !== undefined) {
            lastAjaxLoadLead.abort();
            lastAjaxLoadLead = undefined;
        }
        requestByScrollProgress = true;
        loadLeads(1, $(this).data('type'), $(this).find('.text').html(), 'date', 'DESC');
    });

    //Load the first visible block only if HP
    if ($('#leadMonitoring').length > 0) {
        requestByScrollProgress = true;
        let block = 'received';
        if ($('.board .section').data('init')) {
            block = $('.board .section').data('init');
        }
        loadLeads(1, block, false, 'date', 'desc');
    } else {
        handleHomePaginationLinkAction();
    }
});
